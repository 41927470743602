<template>
  <div class="specialItem">
    <ScaleImage>
      <PlaceholderImage
        :width="720"
        :height="767"
        :src="data.imgUrl"
        :alt="data.title"
      />
      <div class="box">
        <p class="title">{{ data.title }}</p>
        <p
          class="desc"
          v-html="data.desc"
        ></p>
        <img
          class="icon"
          alt=""
          :src="require('@/assets/ShuJiuXiang/icon-big@2x.png')"
        />
      </div>
    </ScaleImage>
  </div>
</template>

<script>
import { Scale, Placeholder } from '@/components/Image';
export default {
  components: {
    ScaleImage: Scale,
    PlaceholderImage: Placeholder,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="scss" scoped>
@use 'sass:math';
@import '@/styles/mixin.scss';
@import '@/styles/ShuJiuXiang/theme.scss';
.specialItem {
  cursor: pointer;
  ::v-deep .scaleImg {
    position: relative;
  }
  .box {
    position: absolute;
    background: #fff;
    left: math.percentage(math.div(120, 720));
    right: math.percentage(math.div(120, 720));
    height: 130px;
    padding: 27px math.percentage(math.div(37, 720)) 0;
    bottom: -13px;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
    .desc,
    .title {
      @include text-nowrap;
    }
    .title {
      font-size: 24px;
      line-height: math.div(32, 24);
    }
    .desc {
      font-size: 16px;
      color: $text-color-6;
      margin-top: 18px;
      height: 21px;
      line-height: 21px;
    }
    .icon {
      position: absolute;
      right: 25px;
      top: 20px;
      width: 24px;
      height: 24px;
    }
  }
  @media screen and (min-width: $hover-point) {
    .box {
      @include hide;
      transform: translateY(20px);
      transition: 400ms;
    }
    &:hover {
      .box {
        @include show;
        transform: none;
      }
    }
  }
  @media screen and (max-width: $res-point-6) {
    .box {
      left: 15px;
      right: 15px;
      padding-left: 25px;
      padding-right: 25px;
    }
  }
}
</style>