<template>
  <div class="content">
    <div class="res">
      <!-- TODO 缺字段 -->
      <StoryTitle class="specialTitle">characteristic dishes</StoryTitle>
      <div v-loading="loading">
        <div
          class="list"
          v-if="listData.length"
        >
          <SpecialItem
            v-for="item in listData"
            :data="item"
            :key="item.id"
            class="item"
            @click.native="goDetail(item)"
          />
        </div>
        <el-empty v-else />
      </div>
    </div>
  </div>
</template>

<script>
import SpecialItem from '../components/Card/Special.vue';
import { getFood } from '@/api/ShuJiuXiang/brand';
import { StoryTitle } from '../components/Title';
export default {
  components: {
    SpecialItem,
    StoryTitle,
  },
  data() {
    return {
      listData: [],
      loading: false,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async goDetail({ id }) {
      this.$router.push(`/sjx/brand/special/detail/${id}`);
    },
    async getData() {
      this.loading = true;
      try {
        const res = await getFood();
        this.loading = false;
        this.listData = res.map((d) => ({
          id: d.foodId,
          title: d.foodName,
          desc: d.foodContent,
          imgUrl: d.foodImage,
        }));
      } catch (err) {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@use 'sass:math';
@import '@/styles/ShuJiuXiang/theme.scss';
.content {
  overflow: hidden;
  padding: math.percentage(math.div(142, 1920)) math.percentage(math.div(175, 1920))
    math.percentage(math.div(134, 1920));
  .res {
    position: relative;
  }
  .specialTitle {
    top: 0;
    left: 0;
    padding-left: math.percentage(math.div(60, 1573));
    position: absolute;
    z-index: 29;
    @media screen and (max-width: $res-point-8) {
      position: static;
      padding-left: 0;
      margin-bottom: math.div(30, 600) * 100vw;
    }
  }
}
.list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .item {
    width: math.percentage(math.div(720, 1573));
    margin-bottom: math.percentage(math.div(156, 1573));
    &:nth-child(2n-1) {
      padding-top: math.percentage(math.div(172, 1573));
    }
  }
}
@media screen and (max-width: $res-point-6) {
  .content {
    padding-left: 5%;
    padding-right: 5%;
  }
}
@media screen and (max-width: $res-point-8) {
  .list {
    .item {
      width: 100%;
      &:nth-child(2n-1) {
        padding-top: 0;
      }
    }
  }
}
</style>