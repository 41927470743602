<template>
  <div class="about-title">
    <p class="en">{{ data.en }}</p>
    <p class="ch">{{ data.ch }}</p>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style lang="scss" scoped>
@use 'sass:math';
@import '@/styles/ShuJiuXiang/theme.scss';
.about-title {
  color: $sub-color-2;
  font-size: 24px;
  line-height: math.div(32, 24);
  border-left: 1px solid $sub-color-2;
  padding-left: 16px;
  .en {
    margin-bottom: 10px;
  }
  @media screen and (max-width: $res-point-3) {
    font-size: 24px * 0.8;
  }
  @media screen and (max-width: $res-point-8) {
    font-size: 24px * 0.8 * 0.8;
  }
}
</style>